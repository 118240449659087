<template>
  <div class='w-screen relative h-screen overflow-hidden'>
    <div class='max-w-[1920px] w-full h-screen my-0 mx-auto max-md:px-0 px-5 parallax' data-rellax-speed='-4'>

      <img src='./assets/logo-a.svg' class='w-2/4 absolute right-[-4.5%] bottom-[-7%] select-none svg-shadow'
           alt='CanoMed logo'>

      <a class='w-64 block' href='/'>
        <img src='./assets/logo-b.svg' alt='Logo'>
      </a>
      <div
          class='ml-5 h-4/6 flex flex-col justify-center text-space-cadet'>
        <h1 class='max-lg:w-[650px] max-md:w-[550px] max-sm:w-[95%] w-[900px] block nunito-650 text-[40px] max-lg:text-[30px] max-md:text-[24px] max-sm:text-[20px] max-sm:inline'>
          Canomed est une société française spécialisée dans la distribution et la conception de produits destinés à
          l’industrie de la santé.
        </h1>
        <div class='mt-8'>
          <button
              @click="scrollTo('contact-section')"
              class='bg-gradient-to-r text-[20px] max-lg:text-[18px] max-md:text-[16px] max-sm:text-[14px] bg-space-cadet text-white font-bold py-3 px-8 rounded-full hover:bg-light-space-cadet transition transition-600 ease-in-out'>
            Nous contacter
          </button>
          <button
              @click="scrollTo('postulation-section')"
              class='ml-10 bg-gradient-to-r text-[20px] max-lg:text-[18px] max-md:text-[16px] max-sm:text-[14px] from-electric-purple to-dodger-blue text-white font-bold py-3 px-8 rounded-full hover:animate-gradient-x'>
            Nous rejoindre
          </button>
        </div>
      </div>

    </div>
  </div>

  <div id='contact-section' class='max-w-[1920px] w-full flex justify-center max-md:px-0 px-5 bg-space-cadet py-24'>
    <div class='w-[804px] max-lg:w-full px-5'>
      <h2 class='text-white block mb-16 text-[40px] max-lg:text-[30px] max-md:text-[24px] max-sm:text-[20px]'>
        Nous contacter
      </h2>
      <div class='flex'>
        <div class='relative text-base z-0 w-[48%] mb-8 group text-white'>
          <input type='text' id='f_name'
                 v-model='formData.f_name'
                 class='rounded-none block py-2.5 px-0 w-full text-white bg-space-cadet border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 focus:border-dodger-blue peer'
                 placeholder=' ' required-none/>
          <label for='f_name'
                 class='peer-focus:font-medium absolute text-white duration-300 transform -translate-y-6 scale-75 top-3 z-40 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
            Nom</label>
        </div>
        <div class='w-[4%]'></div>
        <div class='relative text-base z-0 w-[48%] mb-8 group text-white'>
          <input type='text' id='l_name'
                 v-model='formData.l_name'
                 class='rounded-none block py-2.5 px-0 w-full text-white bg-space-cadet border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 focus:border-dodger-blue peer'
                 placeholder=' ' required/>
          <label for='l_name'
                 class='peer-focus:font-medium absolute text-white duration-300 transform -translate-y-6 scale-75 top-3 z-40 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
            Prénom</label>
        </div>
      </div>


      <div class=''>
        <div class='relative text-base z-0 w-full mb-8 group text-white'>
          <input type='text' id='company'
                 v-model='formData.company'
                 class='rounded-none block py-2.5 px-0 w-full text-white bg-space-cadet border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 focus:border-dodger-blue peer'
                 placeholder=' '/>
          <label for='company'
                 class='peer-focus:font-medium absolute text-white duration-300 transform -translate-y-6 scale-75 top-3 z-40 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
            Société</label>
        </div>
      </div>

      <div class='flex'>
        <div class='relative text-base z-0 w-[48%] mb-8 group text-white'>
          <input type='text' id='address'
                 v-model='formData.address'
                 class='rounded-none block py-2.5 px-0 w-full text-white bg-space-cadet border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 focus:border-dodger-blue peer'
                 placeholder=' ' required/>
          <label for='address'
                 class='peer-focus:font-medium absolute text-white duration-300 transform -translate-y-6 scale-75 top-3 z-40 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
            Adresse</label>
        </div>
        <div class='w-[4%]'></div>
        <div class='relative text-base z-0 w-[48%] mb-8 group text-white'>
          <input type='text' id='city'
                 v-model='formData.city'
                 class='rounded-none block py-2.5 px-0 w-full text-white bg-space-cadet border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 focus:border-dodger-blue peer'
                 placeholder=' ' required/>
          <label for='city'
                 class='peer-focus:font-medium absolute text-white duration-300 transform -translate-y-6 scale-75 top-3 z-40 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
            Ville
          </label>
        </div>
      </div>
      <div class='flex'>
        <div class='relative text-base z-0 w-[48%] mb-8 group text-white'>
          <input type='email' id='email'
                 v-model='formData.email'
                 class='rounded-none block py-2.5 px-0 w-full text-white bg-space-cadet border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 focus:border-dodger-blue peer'
                 placeholder=' ' required/>
          <label for='email'
                 class='peer-focus:font-medium absolute text-white duration-300 transform -translate-y-6 scale-75 top-3 z-40 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
            E-mail</label>
        </div>
        <div class='w-[4%]'></div>
        <div class='relative text-base z-0 w-[48%] mb-8 group text-white'>
          <input type='text' id='phone'
                 v-model='formData.phone'
                 class='rounded-none block py-2.5 px-0 w-full text-white bg-space-cadet border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 focus:border-dodger-blue peer'
                 placeholder=' ' required/>
          <label for='phone'
                 class='peer-focus:font-medium absolute text-white duration-300 transform -translate-y-6 scale-75 top-3 z-40 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
            Téléphone</label>
        </div>
      </div>
      <div class='relative text-base z-0 w-full mb-8 group text-white'>
        <textarea id='message' rows='4'
                  v-model='formData.message'
                  class='rounded-none block py-2.5 px-0 w-full text-white bg-space-cadet border-0 border-b-2 border-white appearance-none focus:outline-none focus:ring-0 focus:border-dodger-blue peer resize-none'
                  placeholder=' ' required></textarea>
        <label for='message'
               class='peer-focus:font-medium absolute text-white duration-300 transform -translate-y-6 scale-75 top-3 z-40 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
          Message
        </label>
      </div>

      <VueHcaptcha
          sitekey="fc1e94d0-9322-43a2-bd32-2f6853f05b60"
          @verify="onVerify"
          @expire="onExpire"
      />

      <button
          @click='submitContactForm'
          class='mt-5 bg-gradient-to-r text-base from-electric-purple to-dodger-blue text-white font-bold py-2.5 px-5 rounded-full hover:animate-gradient-x'>
        Envoyer
      </button>
      <span
          :class="{'text-white': responseMessage === 'Message envoyé avec succès.', 'text-red': responseMessage !== 'Message envoyé avec succès.'}"
          class="ml-6">
      {{ responseMessage }}
    </span>
    </div>
  </div>

  <div id='postulation-section' class='max-w-[1920px] w-full flex justify-center max-md:px-0 px-5 bg-white py-24 mb-16'>
    <div class='w-[804px] max-lg:w-full px-5'>
      <h2 class='text-space-cadet block mb-16 text-[40px] max-lg:text-[30px] max-md:text-[24px] max-sm:text-[20px]'>
        Nous rejoindre
      </h2>
      <div class='flex'>
        <div class='relative text-base z-0 w-[48%] mb-8 group text-space-cadet'>
          <input type='text' id='f_name2'
                 v-model='formData2.f_name'
                 class='rounded-none block py-2.5 px-0 w-full text-space-cadet bg-white border-0 border-b-2 border-space-cadet appearance-none focus:outline-none focus:ring-0 focus:border-dodger-blue peer'
                 placeholder=' ' required-none/>
          <label for='f_name2'
                 class='peer-focus:font-medium absolute text-space-cadet duration-300 transform -translate-y-6 scale-75 top-3 z-40 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
            Nom</label>
        </div>
        <div class='w-[4%]'></div>
        <div class='relative text-base z-0 w-[48%] mb-8 group text-space-cadet'>
          <input type='text' id='l_name2'
                 v-model='formData2.l_name'
                 class='rounded-none block py-2.5 px-0 w-full text-space-cadet bg-white border-0 border-b-2 border-space-cadet appearance-none focus:outline-none focus:ring-0 focus:border-dodger-blue peer'
                 placeholder=' ' required-none/>
          <label for='l_name2'
                 class='peer-focus:font-medium absolute text-space-cadet duration-300 transform -translate-y-6 scale-75 top-3 z-40 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
            Prénom</label>
        </div>
      </div>

      <div class='flex'>
        <div class='relative text-base z-0 w-[48%] mb-8 group text-space-cadet'>
          <input type='text' id='email2'
                 v-model='formData2.email'
                 class='rounded-none block py-2.5 px-0 w-full text-space-cadet bg-white border-0 border-b-2 border-space-cadet appearance-none focus:outline-none focus:ring-0 focus:border-dodger-blue peer'
                 placeholder=' ' required-none/>
          <label for='email2'
                 class='peer-focus:font-medium absolute text-space-cadet duration-300 transform -translate-y-6 scale-75 top-3 z-40 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
            E-mail</label>
        </div>
        <div class='w-[4%]'></div>
        <div class='relative text-base z-0 w-[48%] mb-8 group text-space-cadet'>
          <input type='text' id='phone2'
                 v-model='formData2.phone'
                 class='rounded-none block py-2.5 px-0 w-full text-space-cadet bg-white border-0 border-b-2 border-space-cadet appearance-none focus:outline-none focus:ring-0 focus:border-dodger-blue peer'
                 placeholder=' ' required-none/>
          <label for='phone2'
                 class='peer-focus:font-medium absolute text-space-cadet duration-300 transform -translate-y-6 scale-75 top-3 z-40 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
            Téléphone</label>
        </div>
      </div>
      <div class='relative text-base z-0 w-full mb-8 group text-white'>
        <textarea id='message2' rows='4'
                  v-model='formData2.message'
                  class='rounded-none block py-2.5 px-0 w-full text-space-cadet bg-white border-0 border-b-2 border-space-cadet appearance-none focus:outline-none focus:ring-0 focus:border-dodger-blue peer resize-none'
                  placeholder=' ' required></textarea>
        <label for='message2'
               class='peer-focus:font-medium absolute text-space-cadet duration-300 transform -translate-y-6 scale-75 top-3 z-40 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'>
          Message
        </label>
      </div>

      <div class='relative text-base z-0 w-full mb-8 group text-space-cadet'>
        <label for='annex'
               class='font-medium text-space-cadet block cursor-pointer'>
          <span class='font-bold text-dodger-blue'>
            <svg class='inline mb-1.5' xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='#3793ff'><path d='M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z'/></svg>
            Choisissez un fichier </span>  (PDF uniquement)
        </label>
        <input type='file' id='annex' @change='handleFileUpload' class='w-full text-space-cadet hidden opacity-0' accept=".pdf" />
        <p v-if="fileName" class="text-sm my-2 ml-7 text-space-cadet italic">Fichier sélectionné : {{ fileName }}</p>

      </div>

      <VueHcaptcha
          sitekey="fc1e94d0-9322-43a2-bd32-2f6853f05b60"
          @verify="onVerify"
          @expire="onExpire"
      />

      <button
          @click='submitContactForm2'
          class='mt-5 bg-gradient-to-r text-base from-electric-purple to-dodger-blue text-white font-bold py-2.5 px-5 rounded-full hover:animate-gradient-x'>
        Envoyer
      </button>
      <span
          :class="{'text-space-cadet': responseMessage2 === 'Message envoyé avec succès.', 'text-red': responseMessage2 !== 'Message envoyé avec succès.'}"
          class="ml-6">
      {{ responseMessage2 }}
    </span>
    </div>
  </div>

  <div
      class='max-w-[1920px] w-full flex justify-center items-center max-md:px-0 px-5 py-24 flex-col font-bold text-white bg-space-cadet my-[-100px]'>
    <span class='mb-4'>
      Canomed – 497, rue Hélène Boucher 78530 BUC
    </span>
    <div class='mb-4'>
    <span class=''>
      Tél : 01 85 24 03 03
    </span>
      <span class='ml-4'>
      Fax : 01 30 24 75 47
    </span>
    </div>
    <span class='mb-4'>
      <a href='mailto:contact@canomed.fr'>contact@canomed.fr</a>
    </span>

    <h3 class='mb-4'>Mentions légales</h3>

    <div class="flex w-full text-[10px] font-normal text-center flex-col items-center max-w-[800px] px-4">

      <span class='block font-bold'>RESPONSABLE DE LA PUBLICATION</span>
      <span class='block'>M. BOU KHALED Souhail</span>
      <br>
      <span class='block font-bold mt-2'>TRAITEMENT DES DONNÉES</span>
      <span class='block'>Les données personnelles recueillies au moyen du formulaire, sont destinées à la société CANOMED. En aucun cas, elles ne seront communiquées à des tiers. Ces informations sont conservées de manière confidentielle durant toute la durée de la relation commerciale. Conformément à la loi n° 78-17 du 6 janvier 1978, vous disposez d'un droit d'accès de modification, de rectification et de suppression des données vous concernant. Pour l'exercer, utiliser le formulaire de contact ou adressez-vous à CANOMED.</span>

      <span class='block font-bold mt-2'>DROIT D'AUTEUR</span>
      <span class='block'>L'ensemble de ce site internet relève de la législation française et internationale sur le droit d'auteur et la propriété intellectuelle. Tous les droits de reproduction  sont réservés.</span>
      <br>
      <span class='block font-bold mt-2'>CONTENU</span>
      <span class='block'>Toutes les informations présentes sur le site sont données à titre d'indication. Ces informations ne doivent en aucun cas se substituer aux informations données dans les manuels d'utilisation des produits. Elles ne doivent pas, non plus, être considérées comme recommandation ou conseil pour le médecin ou le prestataire de service. Nous déclinons toute responsabilité quant au contenu des autres sites accessibles depuis les liens présents sur nos pages.</span>

    </div>
  </div>


</template>
<script setup>
import {onMounted, ref} from 'vue';
import Rellax from 'rellax';
import axios from 'axios';
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha'

const formData = ref({
  l_name: '',
  f_name: '',
  company: '',
  address: '',
  city: '',
  email: '',
  phone: '',
  message: ''
});

const formData2 = ref({
  l_name: '',
  f_name: '',
  email: '',
  phone: '',
  message: ''
});

const file = ref(null);
const fileName = ref('');
const responseMessage = ref('')
const responseMessage2 = ref('')
const hcaptchaToken = ref('');
const maxFileSize = 2 * 1024 * 1024; // 2 MB

const handleFileUpload = (event) => {
  const selectedFile = event.target.files[0];
  if (selectedFile) {
    file.value = selectedFile;
    fileName.value = selectedFile.name;
  } else {
    file.value = null;
    fileName.value = '';
  }
};

const onVerify = (token) => {
  hcaptchaToken.value = token;
};

const onExpire = () => {
  hcaptchaToken.value = '';
};

const submitContactForm = async () => {
  if (!hcaptchaToken.value) {
    responseMessage.value = 'Veuillez compléter le captcha.';
    return;
  }

  const fields = [
    { key: 'f_name', label: 'Nom' },
    { key: 'l_name', label: 'Prénom' },
    { key: 'company', label: 'Société' },
    { key: 'address', label: 'Adresse' },
    { key: 'city', label: 'Ville' },
    { key: 'email', label: 'E-mail' },
    { key: 'phone', label: 'Téléphone' },
    { key: 'message', label: 'Message' }
  ];

  for (const field of fields) {
    if (!formData.value[field.key]) {
      responseMessage.value = `Le champ ${field.label} ne peut pas être vide.`;
      return;
    }
  }

  try {
    const response = await axios.post('https://canomed.fr/mailer-7340cfdf-6078-46c5-896c-2dd7f5a92dda.php', {
      ...formData.value,
      'h-captcha-response': hcaptchaToken.value
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    responseMessage.value = 'Message envoyé avec succès.';
  } catch (error) {
    if (error.response) {
      responseMessage.value = error.response.data;
    } else {
      responseMessage.value = 'Une erreur s\'est produite.';
    }
  }
};

const submitContactForm2 = async () => {
  if (!hcaptchaToken.value) {
    responseMessage2.value = 'Veuillez compléter le captcha.';
    return;
  }

  const fields = [
    { key: 'f_name', label: 'Nom' },
    { key: 'l_name', label: 'Prénom' },
    { key: 'email', label: 'E-mail' },
    { key: 'phone', label: 'Téléphone' },
    { key: 'message', label: 'Message' }
  ];

  for (const field of fields) {
    if (!formData2.value[field.key]) {
      responseMessage2.value = `Le champ ${field.label} ne peut pas être vide.`;
      return;
    }
  }

  if (file.value && file.value.size > maxFileSize) {
    responseMessage2.value = 'La taille du fichier ne doit pas dépasser 2 Mo.';
    return;
  }

  const formPayload = new FormData();
  Object.keys(formData2.value).forEach((key) => {
    formPayload.append(key, formData2.value[key]);
  });

  if (file.value) {
    formPayload.append('file', file.value);
  }

  formPayload.append('h-captcha-response', hcaptchaToken.value);

  try {
    const response = await axios.post('https://canomed.fr/mailer-c1b2da5c-b656-4929-a93f-6b710b99f154.php', formPayload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    responseMessage2.value = 'Message envoyé avec succès.';
  } catch (error) {
    if (error.response) {
      responseMessage2.value = error.response.data;
    } else {
      responseMessage2.value = 'Une erreur s\'est produite.';
    }
  }
};


onMounted(() => {
  new Rellax('.parallax');
});


const scrollTo = (id) => {
  const contactSection = document.getElementById(id);
  contactSection.scrollIntoView({behavior: 'smooth'});
};

/*const info = ref({});
const info_description = ref('');
const info_address = ref('');
const info_phone = ref('');
const info_fax = ref('');
const info_email = ref('');
const info_logo = ref('');*/

/*onMounted(() => {

  axios.get(strap_url.value+'/api/company-information?populate=*')
      .then(response => {
        info.value = response.data;
        info_description.value = info.value.data.attributes.description
        info_phone.value = info.value.data.attributes.phone
        info_email.value = info.value.data.attributes.email
        info_fax.value = info.value.data.attributes.fax
        info_logo.value = info.value.data.attributes.logo.data.attributes.url
        info_address.value = info.value.data.attributes.address

        //const info_address = ref('');
      })
      .catch(error => {
        console.error('There was an error fetching the data:', error);
      });
});*/

</script>

<style>
#app {
  font-family: 'Nunito', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.nunito-650 {
  font-family: 'Nunito', sans-serif;
  font-optical-sizing: auto;
  font-weight: 550;
  font-style: normal;
}

.svg-shadow {
  filter: drop-shadow(17px 17px 5px rgba(23, 25, 50, 0.4));
}

</style>
